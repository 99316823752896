<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Lease to Own</h2>
    </div>
  </section>
  <section class="second-section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto">
          <p class="mb-3">
            <span class="fw-bold">E & A Lease</span> offers full-payout lease
            programs, these are leased to own purchases that are
            non-conventional loans specifically designed for the trucking
            industry. Banking institutions normally require high credit scores
            with long history of payments. Here at E & A Lease we offer unique
            programs designed to help customers with
          </p>
          <ul class="mb-3">
            <li>Little or no credit history</li>
            <li>Maxed out credit lines</li>
            <li>Work Permit Authorization</li>
          </ul>
          <p class="fw-bold">
            Contact Anton Churkin to hear more about our programs.
          </p>
          <p class="mb-3">
            Anton Churkin –
            <span class="fw-bold">eandatrucksales@gmail.com</span>
          </p>
          <p>Call E & A Trucks: (312) 678-0974</p>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {};
</script>

<style scoped>
ul {
  padding-left: 1rem;
  list-style: circle;
}
</style>
